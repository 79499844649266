import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>Sobre Campers Factory</h1>
      <p>
        En Campers Factory, somos apasionados por brindar los mejores servicios y productos para los entusiastas del camping. 
        Con años de experiencia en el mantenimiento de campers, personalización y accesorios, aseguramos que tu experiencia de camping sea sin complicaciones y disfrutable.
      </p>
      <p>
        Nuestra misión es ofrecer soluciones de alta calidad para campers que satisfagan las necesidades tanto de los campistas ocasionales como de los aventureros de tiempo completo. 
        Desde la limpieza y mantenimiento de campers hasta la instalación de toldos y la limpieza de parrillas, somos tu tienda integral para todo lo relacionado con campers.
      </p>
      <p>
        ¡Únete a nuestra comunidad de campistas y explora la naturaleza con confianza, sabiendo que Campers Factory está aquí para ayudarte!
      </p>
    </div>
  );
}

export default About;
