import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div className="services-container">
      <header className="services-header">
        <h1>Nuestros Servicios</h1>
      </header>
      
      <section className="services-section">
        <ul>
          <li>Fabricación de campers personalizados</li>
          <li>Limpieza de campers</li>
          <li>Mantenimiento de campers</li>
          <li>Instalación de toldos</li>
          <li>Limpieza de parrillas</li>
          <li>Clubs para campistas</li>
          <li>Overhalling y adecuación de campers</li>
        </ul>
      </section>
      
      <footer className="services-footer">
        <p>Contáctanos para más información sobre nuestros servicios especializados.</p>
      </footer>
    </div>
  );
}

export default Services;
