import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Función para generar el enlace con el mensaje predefinido
  const generateWhatsAppLink = () => {
    const phoneNumber = '+51981216185'; // Reemplaza con el número de WhatsApp
    const text = `Hola, mi nombre es ${name}. Mi correo es ${email}. Quiero consultarte lo siguiente: ${message}`;
    const encodedText = encodeURIComponent(text);
    return `https://wa.me/${phoneNumber}?text=${encodedText}`;
  };

  return (
    <div className="contact-container">
      <header className="contact-header">
        <h1>Contáctanos</h1>
      </header>
      
      <section className="contact-section">
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Tu nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Tu correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje</label>
            <textarea
              id="message"
              name="message"
              placeholder="Tu mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="button-group">
            {/* <button type="submit" className="send-btn">Enviar Email</button> */}
            <a
              href={generateWhatsAppLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp-btn"
            >
              WhatsApp
            </a>
          </div>
        </form>
      </section>
      
      <footer className="contact-footer">
        <p>Te responderemos lo antes posible.</p>
      </footer>
    </div>
  );
};

export default Contact;
