import React from "react";
import "./HomeButton.css";
import { useNavigate } from "react-router-dom";

const HomeButton = ({ title, label, text, link }) => {
  const navigate = useNavigate();

  const handleButtonClick = (link)=> {
    navigate(link);
  }

  return (
    <div className="home-button-container">
      <h2 className="home-title">{title}</h2>

      <p className="home-description">{text}</p>
      <div className="button-content">
        <div className="icon-circle"></div>
        <button className="home-btn" onClick={() => handleButtonClick(link)}>
          {label}
        </button>
      </div>
    </div>
  );
};

export default HomeButton;
