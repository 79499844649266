import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/"><h1>CAMPERS FACTORY</h1></Link>
      </div>
      <nav className="nav">
        <ul className="nav-links">
          <li><Link to="/">INICIO</Link></li>
          <li><Link to="/services">SERVICIOS</Link></li>
          <li><Link to="/about">SOBRE NOSOTROS</Link></li>
        </ul>
      </nav>
      <div className="contact-button">
        <Link to="/contact" className="btn">Contáctanos</Link>
      </div>
    </header>
  );
}

export default Header;
