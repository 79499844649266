import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import ContentBox from './components/ContentBox'; // Importamos el ContentBox
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {/* Aquí añadimos una caja de contenido */}
        {/* <ContentBox 
          title="Welcome to Campers Factory!" 
          content="At Campers Factory, we specialize in providing top-notch services for your campers, from maintenance to accessories. Contact us to learn more about our offers."
        /> */}
      </div>
    </Router>
  );
}

export default App;
