import React from "react";
import "./Home.css"; // Importamos el archivo de estilos
import HomeButton from "../components/HomeButton";

const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <img alt="logo" src="/logo.jpg"/>
      </header>

      <section className="section">
        <HomeButton
          title="Servicios"
          label="ver más"
          text={
            <ul>
              <li>Fabricación de campers personalizados</li>
              <li>Instalación de toldos</li>
              <li>Limpieza de campers y parrillas</li>
            </ul>
          }
          link="/services"
        />
      </section>

      <footer className="home-footer">
        <p>Contacta con nosotros para más información</p>
      </footer>
    </div>
  );
};

export default Home;
